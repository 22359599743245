.thankyoupage{
  display: flex;
}

 .home1 h2{
      text-align: center;
font-size: 38px;
    margin-top: 30px;
    margin-bottom: 30px;
}
.headertxt{
color: #F7379C;
font-size: 26px;
       margin-bottom: 30px;
line-height: 32px;
}
.thanksweepview{
  display: flex;
  position: relative;
      height: 497px;
    /* justify-content: center; */
}
.thanksweepview .sweepstake-login-main{
    margin-top: 0px;
}
.thanksweepview .sweepstake-login-container{
    margin-top: 3px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
}
.thanksweepview #sweepstake-welcome,
.thanksweepview #sweepstake-login-msg,
.thanksweepview  .SweepStake-clientlogo-gen{
    display: none;
}
.thankyoupage .text-wrapper-banner-gen1 {
  width: 58%;
  margin-right: 10px;
}
.thankyoupage .image-wrapper-banner-gen1 {
  width: 32%;
  margin: 0 auto;
  display: flex;
    justify-content: center;
    align-items: center;

}

.thanksweepview .th-join{
        margin-top: 49px;
    font-size: 24px;
    text-align: left;
}
.thanksweepview .SweepStake-condition p:nth-child(2){
display: none;
}
.thanksweepview .sec2{
padding-left: 10px;
width: 100%;
/* display: flex; */
}
.thanksweepview .sec2 p.first{
    margin-top: 10px;
}
.thanksweepview .sec2 .sweepstake-login-container{
display: flex;

}

.thanksweepview .sec2 .sweepstake-login-container{
width: 100%;
position: relative;
}
.thanksweepview .sec2  .sweepstake-social-button-container{
  flex: 0 0 48%;
      margin-right: 30px;
      position: relative;
}

.thanksweepview .sec2  .txtsignup{
  flex: 0 0 38%;
  margin-top: 30px;
}
.thanksweepview .sec2  .SweepStake-condition{
    position: relative;
}

.thanksweepview .sec2  .sweepstake-social-button-container .sweepstake-or-div{
    position: absolute;
    right: -34px;
    top: 7%;
}
.thanksweepview .sec2  .sweepstake-social-button-container .sweepstake-social-button{
padding: 2px;
}
.thanksweepview .sec2  .sweepstake-social-button-container .sweepstake-or-div .sweepstake-or{
color: #000!important;
}

.text-wrapper-banner-gen1 .sec3 {
    position: absolute;
    width: 300px;
    bottom: 60px;
    left: 48px;
}
.text-wrapper-banner-gen1 .sec3 p{
  text-align: left;
font: normal normal 600 20px/48px Montserrat;
letter-spacing: -0.6px;
color: #000000;
}
.text-wrapper-banner-gen1 .sec3 span{
text-align: left;
font: normal normal normal 15px/20px Montserrat;
letter-spacing: -0.45px;
}
