#promotionalheader{
  padding: 5px 20px;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100px;
  /* position: fixed; */
  /* top: 0;
  left: 0;
  right: 0; */
  z-index: 100;
}
#promotionalheader.scrolling {
  background-color: #fff;
    position: fixed;
      height: 70px;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  /* background-color: #EC168C; */
}
#promotionalheader .promotionalheader-wrapper span{
  text-align: left;
font: normal normal 500 21px/25px Montserrat;
letter-spacing: 1px;
color: #000000;
display:block;
}
#promotionalheader.scrolling  .promotionalheader-wrapper span{
font: normal normal 700 21px/25px Montserrat;
margin-left: 10px;

}
#promotionalheader.scrolling  .promotionalheader-wrapper h1{
    display: flex;
    align-items: center;

}
@media screen and (max-width:767px){
/* #promotionalheader.scrolling  .promotionalheader-wrapper h1{
position: relative;
left: -38px;
    margin-left: 0px;
} */

}
#promotionalheader.scrolling #menu1 a {
  color: #fff;
}
#promotionalheader.scrolling #menu1 a:hover {
  color: #750041;
}
#promotionalheader.scrolling #menu1 a.active-menu-item {
  color: #750041;
  padding-bottom: 0;
}
#promotionalheader.scrolling .active-menu-item:after {
  background-color: #ca1176 !important;
  bottom: -21px;
}
#promotionalheader.scrolling #join-free {
  background-color: #CE0F78;
  color: #fff;
  /* color: #ca1176; */
}
#promotionalheader.scrolling #join-free:hover {
  background-color: #CE0F78;
  color: #fff;
}
#promotionalheader-logo{
  width: 122px;
}
#menu1{
  display: flex;
  flex-direction: row;
  align-items: center;
}
#menu1 a {
  padding: 0 15px;
  color:#676767;
  cursor: pointer;
  font-size: 18px;
  text-decoration: none;
  position: relative;
  font-weight: 500;
}
.promotionalheader-wrapper {
  width: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
#menu1 a:hover{
  color:#CE0F78;
}
#menu1 a.active-menu-item {
  color: #C3C3C3;
}

.underline{
  width: 90px;
  background-color: #676767;
  height: 5px;
}
#join-free{
  padding:8px 20px;
  background: #EC168C;
  color:white;
  border: 0;
  box-shadow: none;
  border-radius: 20px;
  cursor: pointer;
font: normal normal 600 18px/19px Montserrat;
  margin-left: 30px;
  min-width: 110px;
  transition: all 0.3s ease;
  position: relative;
  z-index: 1;
}
#join-free:hover {
  background-color: #F7379C;
}

.dropdown-wrapper.hidden{
  display: none;
}

.user-name {
  top: -3px;
}
#menu1 a .arrow {
  font-size: 20px;
  padding-left: 5px;
  display: inline-block;
  transform-origin: 12px 14px;
}
#menu1 a .arrow.open {
  transform: rotate(180deg);
}
#menu1 a:hover .dropdown p .dropdown a {
  color: #676767;
}
#menu1 a .dropdown p:hover , .dropdown a:hover{
  color: #CE0F78;
}
.dropdown .logout {
  font-size: 11px;
  color: #c1c1c1;
  margin-top: 10px;
  text-align: center;
  text-decoration: underline;
}
.dropdown a{
  font-size: 16px !important;
  display: block;
  padding: 5px !important;
}
.dropdown-active{
  color:#EC168C !important;
}
#header.scrolling #menu a .dropdown a {
  color: gray;
}
#header.scrolling #menu a .dropdown a:hover {
  color: #CE0F78;
}
.error-toast {
  position: absolute;
  bottom: -70vh;
  left: 48vw;
  z-index: 11;
  background-color: #ec168c;
  padding: 10px;
  justify-content: center;
  align-self: center;
  align-content: center;
  align-items: center;
  margin: auto;
  border: 1px solid #ec168c;
  border-radius: 15px;
  animation: cashx-fadein 0.5s;
  width: 315px;
}
.error-toast p{
  text-align: center;
  color: white;
  padding: 0px;
  margin: 0px;
}
@keyframes cashx-fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media screen and (max-width:980px){
  #menu1 p{
    font-size:15px;
  }

}
@media screen and (min-width:768px){
     #menu1 #join-free{
    font-size: 15px;
    }
}
@media screen and (max-width:767px){
  #header-logo{
    margin-left: 5vw;
  }
  #menu1{
    margin-right: 5vw;
    display: none;
  }
  #menu1 .sign-up-btn {
    position: absolute;
    top: 0px;
    right: 5px;
}
  #join-free{
    font-size: 13px;
    margin-top:15px;
    padding: 8px 8px;
  }
  #promotionalheader{
height: 50px;
  }
  .promotionalheader-wrapper h1{
    display: block;
    margin: 0 auto;
    text-align: center;
  }
  #promotionalheader .promotionalheader-wrapper span{
    display: none;
  }
}
@media screen and (max-width:618px){
  #header-logo{
    margin-left: 2vw;
  }
  #menu1{
    margin-right: 2vw;
  }
}
