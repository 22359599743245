.reset-password{
  background-color: #FFD800;
  color: black;
  font-weight: bold;
  padding: 11px;
    text-align: center;
    border: 1px solid #ffd800;
    border-radius: 10px;
    cursor: pointer;
}
.reset-password:hover{
  background-color: #ec168c;
  color:white;
  border-color: #ec168c;
}
.success-reset{
  font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin: 55px 0px;
    text-transform: capitalize;
}

.log-in{
  padding:11px 65px;
  font-size: 15px;
}